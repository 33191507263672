import logo from "./logo.png"
import "./App.css"
import { FacebookLogo, InstagramLogo, Envelope, Phone } from "phosphor-react"

function App() {
    return (
        <div className="App" class="bg-black h-screen pl-6 pr-6">
            {/* NAVBAR */}
            <div class="h-16 bg-black flex justify-between items-center pt-10">
                <div class="lg:w-24 w-18 p-1 bg-white flex justify-center align-center rounded-xl">
                    <img src={logo} class="w-16" alt="ssm logo" />
                </div>
                <div class="flex space-x-5">
                    <a
                        href="https://facebook.com/stageandmusic"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FacebookLogo size={32} color="white" />
                    </a>
                    <a
                        href="https://www.instagram.com/sthlmstageandmusic/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <InstagramLogo size={32} color="white" />
                    </a>
                </div>
            </div>
            {/* MAIN */}
            <div class="lg:p-20 mt-20">
                <h1 class="text-5xl lg:text-6xl ">Under Uppbyggnad...</h1>
                <p class="mt-10 text-lg lg:text-xl">
                    Just nu är vår hemsida iväg och får sig en makeover.
                </p>

                <p class="mt-1 text-lg lg:text-xl">
                    Men det går såklart bra att nå oss via mail och telefon
                    ändå.
                </p>

                <div class="flex align-center mt-10">
                    <Envelope size={32} color="white" />
                    <a class="ml-5" href="mailto:tobias@stageandmusic.se">
                        tobias@stageandmusic.se
                    </a>
                </div>
                <div class="flex align-center mt-10">
                    <Phone size={32} color="white" />
                    <a class="ml-5" href="tel:+46706677540">
                        +46 706 67 75 40
                    </a>
                </div>
            </div>
        </div>
    )
}

export default App
